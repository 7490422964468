<template>
    <div id="container">
        <p class="text-secondary fs-5 fw-lighter fst-italic">Anthony Trollope was a leading English novelist of the middle 19th Century. He published 47 novels and 16
            books in several other genres.</p>
        <Biography/>
    </div>


</template>

<script>
    import Biography from "@/components/Biography";

    export default {
        name: "Home",
        setup() {
            document.title = "Trollope - Home";
            return {
                meta: document.title
            };
        },
        components: {Biography}
    }
</script>

<style scoped>
    body {
        background-color: beige;
        font-size: 1.5em;
    }

    p {
        font-family: Nunito, sans-serif;
        font-style: italic;
    }
    div#container{
        margin: 0 auto;
    }
</style>