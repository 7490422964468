<template>
    <!-- -------------------------------------------Usage------------------------------------------- -->
    <!-- Taken from https://learnvue.co/2019/12/building-reusable-components-in-vuejs-tabs/ -->
    <!-- It's actually this video from that page!! -->
    <!--Building a Reusable Tabs Component with Vue Slots-->
    <!-- https://youtu.be/ROGeJxI2L58 -->
    <!-- Note that the Tab component must include the attribute item="" with a string name in order to be
         called correctly. This attribute matches the props property in the component. -->
    <!-- The TabsWrapper component is placed on the page where the tabs are to be shown. Each tab then consists of a
         component that is dropped into an accompanying slot in TabsWrapper. -->

    <!--<TabsWrapper>
            <Tab item="Career">
                <Career/>
            </Tab>
            <Tab item="Bio">
                <PersonalBiography/>
            </Tab>
            <Tab item="Projects">
                <Projects/>
            </Tab>
        </TabsWrapper>-->
    <!-- ------------------------------------------------------------------------------------------- -->

    <div class="tab-content" v-show="item==selectedItem">
        <slot/>
    </div>
</template>

<script>
    import {inject} from 'vue';

    export default {
        props: ['item'],
        name: "Tab",
        setup() {
            const selectedItem = inject('selectedItem');
            return {
                selectedItem
            }
        }
    }
</script>

<style scoped>

    .tab-content {
        min-height: 600px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 3px 5px 5px rgba(0, 0, 0, 0.05);
        background-color: aliceblue;
    }

</style>