<template>
    <div class="container">
        <p>
            Contact Michael Powe at these places.
        </p>
        <p class="contact">
            Social Media: You can't. I no longer use Twitter or Facebook. If you contact me via Messenger, I may or may not respond.
        </p>
        <p class="contact">
            LinkedIn: (infrequently) You may message me here, but responses are likely to be occasional or not at all.
        </p>
        <p class="contact">
            Email: powem-at-ctpowe.net : The only place you can be sure of reaching me.
        </p>
    </div>
</template>

<script>
    export default {
        name: "Contact",
        setup() {
            document.title = "Trollope - Contact";
            return {
                meta: document.title
            };
        }
    }
</script>

<style scoped>
    p.contact {
    font-size: 0.9em;
    }

</style>
