<template>
    <div class=" container bio">
        <p class="text-start ps-5 pe-5" id="intro">
            He was the son of barrister Thomas Anthony Trollope and the novelist/travel writer Frances
            Milton Trollope. His father failed as a barrister due to his inability to control his temper. He
            subsequently tried and failed as a "gentleman farmer." Trollope later wrote that his boyhood was miserable
            because of the disparity between his parents' privileged background and their lack of means to maintain that
            lifestyle.
        </p>
        <h2>Brief Chronology</h2>
        <div class="mx-5">
            <p class="date text-start text-secondary fs-6 mb-0">
                <span class="fw-bold">24 April 1815</span>. Born, London, England
            </p>
            <p class=" date text-start text-secondary fs-6">
                <span class="fw-bold">6 December 1882</span>. Died, Marylebone, Westminster
            </p>
        </div>
        <hr class="mx-auto text-primary">
        <p class="text-start fs-6">
            <span class="fw-bold">1834</span>. Thomas Trollope's financial failures drive the family to flee England
            to Belgium, where they live off the earnings of Frances Trollope's books.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">Autumn 1834</span>. Sponsored by a family friend, Anthony is offered a clerkship in
            the General Post Office. He returns to London from Belgium to take up this post.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1835</span>. Anthony's father dies.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1841</span>. Trollope volunteers for a position as postal surveyor's clerk in Ireland.
            His supervisor is only too happy to get the unreliable and troublesome clerk out of his office, and appoints
            him to the position.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1844</span>. In Ireland, Trollope meets Rose Heseltine and they marry. Both
            their sons, Henry Merivale Trollope (13 Mar 1846 - 24 Mar 1926) and Frederick James Anthony Trollope
            (27 Sep 1847 - 31 May 1910), are born in Ireland.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1859</span>. He is given a position as Surveyor to the Post Office's Eastern District.
            Later in that year the family moves to Waltham Cross in Hertfordshire, where they live until 1871.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1867</span>. His Barsetshire series of novels has become successful. Now
            an established writer, with sufficient savings and income, he resigns his position at the Post Office.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1868</span>. He stands for Parliament for the borough of Beverley, in Yorkshire. His
            campaign completely fails, in spite of having spent a considerable amount of his own money on it. The
            election is subsequently investigated by a Royal Commission, which finds so much corruption that the
            borough is disenfranchised in 1870. This is Trollope's only venture into politics. After this, he is content
            to view politics from the outside.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1871</span>. He continues his peripatetic lifestyle. He spends over a year in
            Australia with his son Frederick, who had a sheep ranch there.
        </p>
        <p class="text-start fs-6">
            <span class="fw-bold">1882</span>. Trollope dies in Marylebone, London and is buried in Kensal Green
            Cemetery.
        </p>
    </div>
</template>

<script>
    export default {
        name: "Biography",
        setup() {

        }
    }
</script>

<style scoped>
    hr {
        width: 40%;
        height: 2px;

    }

    #intro {
        font-size: 0.9rem;
    }

    .date {
        font-family: Nunito,sans-serif;
        font-size: 0.9rem;
    }

    body {

    }

    /*   p {
           text-align: left;
           margin-left: 10em;
           margin-right: 10em;
       }



       .bio {
           font-size: 0.8em;

       }

       .life {
           margin: 0 0 0 10em;
       }*/
</style>