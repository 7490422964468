<template>
    <div class="container" id="app">
        <h2>{{name}}</h2>
        <ul>
            <li v-for="novel in novels" :key="novel.PublicationDate">
                {{novel.PublicationDate}} -- {{novel.Title}}
            </li>
        </ul>
    </div>
</template>

<script>
    import singleNovels from "@/assets/anthony-trollope-single-novels";

    export default {
        name: 'DisplayNovels',
        components: {},
        setup() {
            return {
                novels: singleNovels,
                name: 'Single Novels'
            };
        }
    }
</script>

<style scoped>

    li {
        text-align: left;
    }

</style>