<template>
    <div class="container" id="main">
        <p class="text-secondary fs-5 fw-lighter fst-italic">This web site is the work of Michael Powe, who has been a
            fan of Anthony Trollope's writing for more than 30
            years.</p>
        <p class="text-warning fw-bold fst-italic" id="motto">"Mistakes were made, but not by me."</p>
        <!-- Note: the string name given to the tab component is what appears on the tab on the page. It can be
             any string. -->
        <TabsWrapper>
            <Tab item="Biography">
                <PersonalBiography/>
            </Tab>
            <Tab item="Career">
                <Career/>
            </Tab>
            <Tab item="Projects">
                <Projects/>
            </Tab>
        </TabsWrapper>
    </div>
</template>

<script>
    import TabsWrapper from "@/components/TabsWrapper";
    import Tab from "@/components/Tab";
    import Career from "@/components/About/Career";
    import PersonalBiography from "@/components/About/PersonalBiography";
    import Projects from "@/components/About/Projects";

    export default {
        name: "About",
        components: {Projects, PersonalBiography, Career, TabsWrapper, Tab},
        setup() {
            document.title = "Trollope - About";
            return {
                meta: document.title
            };
        },
        methods: {}
    }
</script>

<style scoped>

    /*   #motto {
        font-size: 1em;
    }

    p {
        font-size: 0.9em;
    }
*/
</style>