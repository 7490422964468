<template>
   <div class="footer">
       <ul class="bd-footer text-end p-3" >
           <li class="d-inline-block">&copy; 1998-{{copyright}}</li>
           <li class="d-inline-block ms-3"><router-link to="/about">About</router-link></li>
           <li class="d-inline-block ms-3"><router-link to="/about/contact">Contact</router-link></li>
       </ul>
   </div>
</template>

<script>
    import {ref} from 'vue';

    export default {
        name: "Footer",
        setup(){
            let copyright = ref('');
            copyright = new Date().getFullYear();
            return {copyright};
            }
    }
</script>

<style scoped>
    .bd-footer{
        font-size: .875rem;
        background-color: aliceblue;
    }
 /*   .footer{
        font-size: 0.9em;
        font-family: "Lucida Bright";
        background-color: aliceblue;
    }

    div.footer ul li{
        display: inline-block;
        list-style-type: none;
        padding: 2em;
    }*/
</style>