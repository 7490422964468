<template>
    <div class="container">
        <p class="text-start fw-bold fs-6 text-info">This is my bio.</p>
        <p class="text-start fst-italic fs-6">In the beginning, I was born.</p>

        <p class="text-start">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu porta elit, a aliquet
            ligula. Etiam sit amet tristique mi. Nullam a tellus scelerisque, suscipit tellus nec, placerat tellus. Sed
            maximus, orci facilisis facilisis fringilla, massa felis lobortis ligula, non accumsan erat lacus id quam.
            Aliquam risus nisl, facilisis eget euismod ut, lacinia non sem. Mauris sed eros scelerisque, lobortis nisi
            sit amet, pretium metus. Fusce ac porta lacus.
        </p>
        <p class="text-start">
            In ac urna vitae magna mollis mollis. Suspendisse ultrices varius ex, id commodo purus malesuada a. Mauris
            nunc augue, consectetur ac nibh quis, laoreet placerat dui. Pellentesque id ex accumsan, pulvinar tellus
            vitae, vehicula sem. Nunc eget consectetur lorem. Etiam tincidunt efficitur arcu. Nullam vel imperdiet mi.
            Curabitur sapien odio, consequat at dignissim in, ultrices vulputate lectus. In finibus, leo imperdiet
            facilisis sollicitudin, nisl dui lobortis libero, in tincidunt purus nunc nec sem.
        </p>
        <p class="text-start">Morbi sodales, neque in malesuada molestie, lorem ex euismod lorem, non dapibus ipsum
            felis ut lacus. Praesent nec varius neque. Phasellus luctus molestie dignissim. Phasellus in urna molestie,
            tempor elit a, vestibulum ligula. Phasellus et tellus id eros volutpat scelerisque eget a urna. Pellentesque
            ultricies justo ac turpis interdum, at porta ante pulvinar. In et egestas odio. Nam egestas orci lacinia
            nisl vestibulum convallis. Sed gravida at eros vitae volutpat.
        </p>
        <p class="text-start">Duis tristique eleifend tellus vel tempor. Suspendisse tempor mi turpis, ut venenatis urna
            bibendum sit amet. Morbi turpis dolor, pellentesque at metus vel, pellentesque feugiat tellus. Aliquam erat
            volutpat. In hac habitasse platea dictumst. Morbi bibendum eu mi eu porta. Suspendisse enim leo, luctus sit
            amet efficitur quis, finibus sit amet purus. Maecenas tincidunt urna quis diam porttitor viverra. Sed a
            fermentum mauris. Proin eros arcu, tincidunt eget eros sit amet, semper semper orci. Integer auctor lacinia
            quam sit amet blandit. Sed hendrerit magna vitae mauris congue, ultrices dictum eros cursus.
        </p>
        <p class="text-start">Cras lobortis magna quis quam scelerisque tincidunt. Praesent porta est vel molestie
            vehicula. Cras commodo nunc eget leo pulvinar, non pulvinar nunc imperdiet. Sed aliquet est in faucibus
            gravida. Etiam sit amet lacinia dui. Quisque quis dolor nec lacus pellentesque tincidunt non a lacus.
            Pellentesque et nisi ac elit egestas venenatis a at nisi. Praesent convallis, elit ac laoreet tincidunt,
            turpis magna auctor nisi, eget consectetur dolor nulla ullamcorper mauris. Morbi eget lacinia nulla, vitae
            varius nibh. In egestas nisl velit, ut luctus sapien porta congue. Nulla sed est mollis, pellentesque arcu
            id, efficitur ex. Cras condimentum nunc nec risus vestibulum,non rhoncus metus ullamcorper. Nullam iaculis
            urna a libero aliquet semper.
        </p>
    </div>
</template>

<script>
    export default {
        name: "PersonalBiography"
    }
</script>

<style scoped>
    p {
        font-size: 0.9rem;
    }

    /*  p{
          text-align: left;
          font-size: 0.8em;
      }*/

</style>