<template>
    <div class="container">
        <h3>Frances Eleanor Trollope</h3>
        <p>
            Sister of Ellen Ternan and spouse of Thomas Adolphus Trollope.
        </p>
        <div class="mx-5">
            <p class="date text-start text-secondary fs-6 mb-0">
                <span class="fw-bold">August 1834</span>. Born, Delaware, United States.
            </p>
            <p class="date text-start text-secondary fs-6">
                <span class="fw-bold"> 14 August 1913</span>. Died, Southsea, England.
            </p>
        </div>
        <hr class="mx-auto text-primary">
        <div id="text">
            <p class="text-start">
                Tempor orci eu lobortis elementum nibh tellus molestie nunc non. Egestas quis ipsum suspendisse ultrices
                gravida dictum fusce ut. Et netus et malesuada fames ac turpis egestas sed tempus. Nunc aliquet bibendum
                enim facilisis gravida neque. Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Hac
                habitasse platea dictumst quisque sagittis purus sit amet. Elit ut aliquam purus sit amet. Felis eget
                nunc lobortis mattis aliquam faucibus purus in massa. Faucibus pulvinar elementum integer enim. Morbi
                non arcu risus quis varius quam quisque. Tristique risus nec feugiat in fermentum posuere. Leo urna
                molestie at elementum eu. Faucibus scelerisque eleifend donec pretium vulputate. At risus viverra
                adipiscing at in tellus integer feugiat scelerisque. Turpis tincidunt id aliquet risus feugiat in ante
                metus dictum. Quisque sagittis purus sit amet volutpat consequat. Lectus sit amet est placerat in.
            </p>
            <p class="text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Et molestie ac feugiat sed lectus. Ornare arcu dui vivamus arcu felis bibendum ut.
                Pharetra diam sit amet nisl suscipit adipiscing bibendum est. Interdum consectetur libero id faucibus.
                Scelerisque eu ultrices vitae auctor eu augue ut lectus. Sagittis id consectetur purus ut faucibus
                pulvinar elementum integer enim. Morbi blandit cursus risus at ultrices mi. Fringilla est ullamcorper
                eget nulla facilisi etiam dignissim diam quis. Massa eget egestas purus viverra accumsan in nisl nisi.
                Ornare massa eget egestas purus viverra accumsan in nisl. Duis convallis convallis tellus id interdum.
                Vitae elementum curabitur vitae nunc sed velit. Nam libero justo laoreet sit amet cursus sit amet.
                Ornare suspendisse sed nisi lacus sed viverra tellus.

            </p>
        </div>
    </div>
</template>

<script>
    //import NavBarSub from "@/components/Family/NavBarSub";

    export default {

        name: "FamilyFrancesEleanor",
        components: {}, //NavBarSub
        setup() {
            document.title = 'Trollope - Frances Eleanor';
            return {
                meta: document.title
            };
        }
    }
</script>

<style scoped>
    hr{
        width: 40%;
        height: 2px;

    }
    .date {
        font-family: Nunito,sans-serif;
        font-size: 0.9rem;
    }
    /* p {
         text-align: left;
         font-size: 0.8em;
     }

     .date {
         font-weight: bold;
     }

     .spacer {
         background-color: aliceblue;
         margin-top: 0;
     }*/
</style>