<template>
    <div id="app">
        <NavBar/>
        <router-view/>

        <Footer/>
    </div>
</template>

<script>
    import NavBar from "@/components/NavBar";
    import Footer from "@/components/Footer";

    export default {
        name: 'App',
        components: {NavBar, Footer}
    };
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,700;1,400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

    @import "assets/style.css";

    #app {
        font-family: Noto Serif,serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        background-color: white;
        color: #2c3e50;
        margin-top: 0;
    }

</style>
