<template>
    <div class="container"></div>
</template>

<script>
    export default {
        name: "Details",
        setup(){

        }
    }
</script>

<style scoped>

</style>