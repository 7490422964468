<template>
    <!-- -------------------------------------------Usage------------------------------------------- -->
    <!-- The wrapper component goes on the page to display the tabs -->
    <!-- Taken from https://learnvue.co/2019/12/building-reusable-components-in-vuejs-tabs/ -->
    <!-- It's actually this video from that page!! -->
    <!--Building a Reusable Tabs Component with Vue Slots-->
    <!-- https://youtu.be/ROGeJxI2L58 -->
    <!--<TabsWrapper>
            <Tab item="Career">
                <Career/>
            </Tab>
            <Tab item="Bio">
                <PersonalBiography/>
            </Tab>
            <Tab item="Projects">
                <Projects/>
            </Tab>
        </TabsWrapper>-->
    <!-- ------------------------------------------------------------------------------------------- -->
    <div class="tabs">

        <ul class="tabs_header">
            <li v-for="item in tabItems"
                :key="item"
                :class="{selected: item == selectedItem}"
                @click="selectedItem = item">
                {{item}}
            </li>

        </ul>
        <slot/>
    </div>
</template>

<script>
    import {ref, provide} from 'vue';

    export default {
        name: "TabsWrapper",
        setup(props, {slots}) {
            const tabItems = ref(slots.default()
                .map((tab) => tab.props.item));
            const selectedItem = ref(tabItems.value[0]);
            provide('selectedItem', selectedItem);
            return {selectedItem, tabItems};
        }
    }
</script>

<style scoped>

    .tabs {
        max-width: 800px;
        margin: 0 auto;
    }

    .tabs_header {
        margin-bottom: 0;
        list-style: none;
        padding: 0;
        display: flex;
    }

    .tabs_header li {
        width: 100px;
        text-align: center;
        font-size: 0.9em;
        font-weight: bold;
        padding: 10px 20px;
        margin-right: 0;
        background-color: #ddd;
        border-radius: 10px 10px 0px 0px;


        cursor: pointer;
        transition: 0.4s all ease-out;
    }

    .tabs_header li.selected {
        background-color: aliceblue;
        color: #2c3e50;
    }

</style>