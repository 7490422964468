<template>
    <div class="container">
        <h2>The Palliser Novels</h2>
        <div class="flex">
            <div class="flex-item1" id="text">
                <p class="text-start">
                    Tempor orci eu lobortis elementum nibh tellus molestie nunc non. Egestas quis ipsum suspendisse
                    ultrices gravida dictum fusce ut. Et netus et malesuada fames ac turpis egestas sed tempus. Nunc
                    aliquet bibendum enim facilisis gravida neque. Volutpat consequat mauris nunc congue nisi vitae
                    suscipit tellus. Hac habitasse platea dictumst quisque sagittis purus sit amet. Elit ut aliquam
                    purus sit amet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. Faucibus pulvinar
                    elementum integer enim. Morbi non arcu risus quis varius quam quisque. Tristique risus nec feugiat
                    in fermentum posuere. Leo urna molestie at elementum eu. Faucibus scelerisque eleifend donec pretium
                    vulputate. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Turpis tincidunt id
                    aliquet risus feugiat in ante metus dictum. Quisque sagittis purus sit amet volutpat consequat.
                    Lectus sit amet est placerat in.
                </p>
                <p class="text-start">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Et molestie ac feugiat sed lectus. Ornare arcu dui vivamus arcu felis
                    bibendum ut. Pharetra diam sit amet nisl suscipit adipiscing bibendum est. Interdum consectetur
                    libero id faucibus. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Sagittis id consectetur
                    purus ut faucibus pulvinar elementum integer enim. Morbi blandit cursus risus at ultrices mi.
                    Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis. Massa eget egestas purus
                    viverra accumsan in nisl nisi. Ornare massa eget egestas purus viverra accumsan in nisl. Duis
                    convallis convallis tellus id interdum. Vitae elementum curabitur vitae nunc sed velit. Nam libero
                    justo laoreet sit amet cursus sit amet. Ornare suspendisse sed nisi lacus sed viverra tellus.

                </p>
            </div>

            <div class="flex-item2">
                <table class="table table-bordered border-primary table-sm">
                    <thead class="table-dark">
                    <tr>
                        <td>Publication Date</td>
                        <td>Title</td>
                        <td>Publisher</td>
                        <td>Notes</td>
                    </tr>
                    </thead>
                    <tr v-for="novel in novels" :key="novel.PublicationDate">
                        <td class="border-1">{{novel.PublicationDate}}</td>
                        <td class="text-start border-1">{{novel.Title}}</td>
                        <td class="text-start border-1">{{novel.Publisher}}</td>
                        <td class="text-start border-1">{{novel.Notes}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import pallisers from '../../assets/anthony-trollope-pallisers-novels'

    export default {
        name: "Pallisers",
        setup() {
            document.title = 'Trollope - Pallisers';
            return {
                novels: pallisers,
                meta: document.title
            };
        }
    }
</script>

<style scoped>
    /* p {
         text-align: left;
         font-size: 0.8em;
     }

     .flex {
         display: flex;
     }

     .flex-item1 {
         flex-basis: 40%;
     }

     .flex-item2 {

     }*/
</style>