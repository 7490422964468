<template>
    <div>
        <nav class="navbar bg-dark navbar-dark navbar-expand-sm">
            <div class="container-fluid" id="nav">
                <button class="navbar-toggler" type="button"
                        data-bs-toggle="collapse" data-bs-target="#mainNav"
                        aria-controls="myTogglerNav"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-brand">
                    <img src="../assets/images/trol5.5.png" alt="Anthony Trollope" width="100">
                    <span class="display-5"> Anthony Trollope |</span>

                </div><!-- end navbar-brand-->

                <div class="collapse navbar-collapse" id="mainNav">
                    <div class="navbar-nav">

                        <router-link class="nav-link" to="/" active-class="active">Home</router-link>
                        <router-link class="nav-link" to="/irish-novels" active-class="active">Irish Novels
                        </router-link>
                        <router-link class="nav-link" to="/barsetshire" active-class="active">Barsetshire Novels
                        </router-link>
                        <router-link class="nav-link" to="/pallisers" active-class="active">Palliser Novels
                        </router-link>
                        <router-link class="nav-link" to="/articles" active-class="active">Articles</router-link>
                        <router-link class="nav-link" to="/nonfiction" active-class="active">Nonfiction Works
                        </router-link>
                        <!-- begin Family dropdown menu-->
                        <div class="dropdown">
                            <router-link class="nav-link dropdown-toggle text-info" data-bs-toggle="dropdown"
                                         id="bio"
                                         to="#"
                                         active-class="active">Family
                            </router-link>

                            <div class="dropdown-menu"><!-- begin dropdown menu-->
                                <router-link class="dropdown-item" to="/family">Overview</router-link>
                                <router-link class="dropdown-item"
                                             to="/family/fanny-trollope">Frances Milton Trollope (1780-1863)
                                </router-link>
                                <router-link class="dropdown-item"
                                             to="/family/thomas-adolphus-trollope">Thomas Adolphus Trollope
                                    (1810-1892)
                                </router-link>

                                <router-link class="dropdown-item"
                                             to="/family/frances-eleanor">Frances Eleanor Trollope (1835-1913)
                                </router-link>

                            </div><!-- end dropdown menu-->

                        </div>
                    </div><!-- end navbar-nav -->
                </div><!-- end navbar-collapse -->
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "NavBar",
        setup() {

        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

    #mainNav {
        font-family: Nunito, sans-serif;
    }

    .navbar-brand {
        font-family: Pacifico, serif;
    }

    /*   #nav {
           background-color: aliceblue;
           margin: 0 auto;
           padding-bottom: 1em;
       }

       .nav {
           text-align: center;
           border-radius: 20px;
           background-color: #2c3e50;
           color: white;
           width: fit-content;
           margin: 0 auto;
           padding: 0.5em;
       }

       .active {
           color: coral;
       }

       a {
           color: white;
           font-size: 0.8em;
           text-decoration: none;
       }

       .logo img {
           float: left;
           margin-bottom: 0;
       }
   */
</style>