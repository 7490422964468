/*
routes for Trollope app
 */

import {createRouter, createWebHistory} from 'vue-router';
// import {Vue} from 'vue';
import Home from "@/components/Home";
import Details from "@/components/Details";
import DisplayWorks from "@/components/DisplayWorks";
import Pallisers from "@/components/Works/Pallisers";
import Barsetshire from "@/components/Works/Barsetshire";
import works from "@/components/Works/Articles";
import Nonfiction from "@/components/Works/Nonfiction";
import Irish from "@/components/Works/Irish";
import Family from "@/components/Family/Family";
import Contemporaries from "@/components/Contemporaries";
import FamilyFrances from "@/components/Family/FamilyFrances";
import FamilyThomasAdolphus from "@/components/Family/FamilyThomasAdolphus";
import FamilyFrancesEleanor from "@/components/Family/FamilyFrancesEleanor";
import About from "@/components/About";
import Contact from "@/components/Contact";

const pageTitlePrefix = 'New Trollope - ';

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {title: pageTitlePrefix + 'Home'},
        component: Home
    },
    {
        path: '/details',
        name: 'Details',
        meta: {title: pageTitlePrefix + 'Details'},
        component: Details
    },
    {
        path: '/single-novels',
        name: 'SingleNovels',
        meta: {title: pageTitlePrefix + 'Single Novels'},
        component: DisplayWorks
    },
    {
        path: '/pallisers',
        name: 'Pallisers',
        meta: {title: pageTitlePrefix + 'Pallisers'},
        component: Pallisers
    },
    {
        path: '/barsetshire',
        name: 'Barsetshire',
        meta: {title: pageTitlePrefix + 'Barsetshire'},
        component: Barsetshire
    },
    {
        path: '/articles',
        name: 'works',
        meta: {title: pageTitlePrefix + 'Articles'},
        component: works
    },
    {
        path: '/nonfiction',
        name: 'Nonfiction',
        meta: {title: pageTitlePrefix + 'Nonfiction'},
        component: Nonfiction
    },
    {
        path: '/irish-novels',
        name: 'IrishNovels',
        meta: {title: pageTitlePrefix + 'Irish'},
        component: Irish
    },
    {
        path: '/family',
        name: 'Family',
        meta: {title: pageTitlePrefix + 'Family'},
        component: Family
    },
    {
        path: '/contemporaries',
        name: 'Contemporaries',
        meta: {title: pageTitlePrefix + 'Contemporaries'},
        component: Contemporaries
    },
    {
        path: '/family/fanny-trollope',
        name: 'Frances',
        meta: {title: pageTitlePrefix + 'Fanny Trollope'},
        component: FamilyFrances
    },
    {
        path: '/family/thomas-adolphus-trollope',
        name: 'ThomasAdolphus',
        meta: {title: pageTitlePrefix + 'Thomas Adolphus'},
        component: FamilyThomasAdolphus
    },
    {
        path: '/family/frances-eleanor',
        name: 'FrancesEleanor',
        meta: {title: pageTitlePrefix + 'Frances Eleanor'},
        component: FamilyFrancesEleanor
    },
    {
        path: '/about',
        name: 'About',
        meta: {title: pageTitlePrefix + 'About'},
        component: About
    },
    {
        path: '/about/contact',
        name: 'Contact',
        meta: {title: pageTitlePrefix + 'Contact'},
        component: Contact
    }

];
//history: createWebHistory(process.env.BASE_URL),
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active'
});

/*eslint no-unused-vars: "warn"*/
/* router.afterEach((to, from) => {
    Vue.nextTick(async () => {
        document.title = to.meta.title;
    }).then(r => {
        return document.title;
    });
}); */


export default router;