import {createApp} from 'vue';
import App from './App.vue';
import router from './router/index';
import {createGtm} from "vue-gtm";
import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.min.css';

/*
Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
}).$mount('#app');
*/

createApp(App)
    .use(router)
    .use(
        createGtm({
            id: 'GTM-T44VMGH',
            queryParams: {},
            defer: false,
            enabled: true,
            debug: false,
            vueRouter: router
        })
    )
    .mount('#app');

