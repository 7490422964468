<template>
    <div class="container">
        <h2>The Nonfiction Works</h2>
        <div class="flex">
            <div class="flex-item1" id="text">
                <p class="text-start">
                    While I was still learning my duty as an usher at Mr. Drury's school at Brussels, I was summoned to
                    my clerkship in the London Post Office, and on my way passed through Bruges. I then saw my father
                    and my brother Henry for the last time. A sadder household never was held together. They were all
                    dying; except my mother, who would sit up night after night nursing the dying ones and writing
                    novels the while,—so that there might be a decent roof for them to die under. Had she failed to
                    write the novels, I do not know where the roof would have been found. It is now more than forty
                    years ago, and looking back over so long a lapse of time I can tell the story, though it be the
                    story of my own father and mother, of my own brother and sister, almost as coldly as I have often
                    done some scene of intended pathos in fiction; but that scene was indeed full of pathos. I was then
                    becoming alive to the blighted ambition of my father's life, and becoming alive also to the violence
                    of the strain which my mother was enduring. But I could do nothing but go and leave them. There was
                    something that comforted me in the idea that I need no longer be a burden,—a fallacious idea, as it
                    soon proved. My salary was to be £90 a year, and on that I was to live in London, keep up my
                    character as a gentleman, and be happy. That I should have thought this possible at the age of
                    nineteen, and should have been delighted at being able to make the attempt, does not surprise me
                    now; but that others should have thought it possible, friends who knew something of the world, does
                    astonish me. - The Autobiography, Ch 3
                </p>
                <p class="text-start">
                    It was my practice to be at my table every morning at 5.30 A.M.; and it was also my practice to
                    allow myself no mercy. An old groom, whose business it was to call me, and to whom I paid £5 a year
                    extra for the duty, allowed himself no mercy. During all those years at Waltham Cross he was never
                    once late with the coffee which it was his duty to bring me. I do not know that I ought not to feel
                    that I owe more to him than to any one else for the success I have had. By beginning at that hour I
                    could complete my literary work before I dressed for breakfast.
                    - The Autobiography, Ch 15 </p>
            </div>
            <div class="flex-item2">
                <table class="table table-bordered border-primary table-sm">
                    <thead class="table-dark">
                    <tr>
                        <td>Publication Date</td>
                        <td>Title</td>
                        <td>Publisher</td>
                        <td>Notes</td>
                    </tr>
                    </thead>
                    <tr v-for="work in works" :key="work.PublicationDate">
                        <td class="border-1">{{work.PublicationDate}}</td>
                        <td class="text-start border-1">{{work.Title}}</td>
                        <td class="text-start border-1">{{work.Publisher}}</td>
                        <td class="text-start border-1">{{work.Notes}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import nonfiction from "@/assets/anthony-trollope-nonfiction";

    export default {
        name: "Nonfiction",
        setup() {
            document.title = 'Trollope - Nonfiction';
            return {
                works: nonfiction,
                meta: document.title
            };
        }
    }

</script>

<style scoped>
    /*   p {
           text-align: left;
           font-size: 0.8em;
       }

       .flex {
           display: flex;
       }

       .flex-item1 {
           flex-basis: 40%;
       }

       .flex-item2 {

       }*/
</style>